/* custom-map.css */

/* Estilo para el contenedor del mapa */
.map-container {
    border: 2px solid #333; /* Bordes oscuros */
    border-radius: 10px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra */
  }
  
  /* Estilo para los controles del mapa */
  .leaflet-control-zoom-in, .leaflet-control-zoom-out {
    background-color: #fcd447 !important; /* Fondo amarillo */
    border: 1px solid #333 !important; /* Borde oscuro */
    color: #333 !important; /* Texto oscuro */
  }
  
  /* Estilo para el tooltip del marcador */
  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background: #fcd447; /* Fondo amarillo */
    color: #333; /* Texto oscuro */
    border: 1px solid #333; /* Borde oscuro */
  }
  
  /* Opcional: Estilo para el marcador */
  .custom-marker {
    background-color: #fcd447 !important; /* Fondo amarillo */
    border: 2px solid #333 !important; /* Borde oscuro */
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
  